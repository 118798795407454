/* You can add global styles to this file, and also import other style files */
// @import "~bootswatch/dist/flatly/_variables.scss";
@import "~bootstrap/scss/bootstrap.scss";

@import '~ngx-toastr/toastr-bs4-alert';

// @import "~bootswatch/dist/flatly/_bootswatch.scss";
@import 'variables';
@import 'colors';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
// @import '../node_modules/@fortawesome/fontawesome-free/scss/regular';

html,
body,
header,
#intro {
  height: 100%;
  scroll-behavior: smooth;
}

body {
    /* display: -ms-flexbox;
    display: flex; */
    /* color: #fff; */
    /* text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5); */
  }

.malcom-navbar {
  background-color: $gray-800;
}

#intro {
  /* background: url("../images/DSC03766.jpg")no-repeat center center fixed; */
  // background: url("https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Fibonacci_spiral_34.svg/2000px-Fibonacci_spiral_34.svg.png")no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: $gray-800;
  /* max-width: 42em; */
  /* color: #fff; */
}

#intro > .container-fluid {
    max-width: 43em;
}

#smooth {
  scroll-behavior: smooth;
}

